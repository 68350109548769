.contact-main-container {
  height: fit-content;
  width: 100%;
  /* background: #000; */
  margin-top: 12vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  /* margin-bottom: 2vh; */
  background-color: #ffffff;
  opacity: 1;
  /* background-image: radial-gradient(#50844d 0.4px, #ffffff 0.4px); */
  background-size: 8px 8px;
}

.contact-title {
  height: 10vh;
  width: 80%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-1);
  display: flex;
  align-items: flex-start;
  color: var(--font-color-1);
}

.contact-section-1-container {
  height: fit-content;
  width: 80%;
  /* background: rgb(224, 224, 224); */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.contact-left-container {
  height: fit-content;
  width: 50%;
  margin-bottom: 10px;
  /* background: rgb(229, 229, 229); */
}

.form-container {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  /* padding: 15px; */
  /* background: rgb(190, 215, 45); */
}

.contact-form-input-warp {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  /* background: rgb(194, 14, 14); */
  gap: 5px;
}

.form-input {
  width: 80%;
  height: 5vh;
  background: #f1f1f1;
  border-radius: 5px;
  border: none;
}

input:out-of-range {
  background-color: #e64848;
  border: 2px solid red;
}

input:in-range {
  background-color: #51eb51;
}

.form-text {
  width: 80%;
  height: 10vh;
  background: #f1f1f1;
  border-radius: 5px;
  border: none;
  resize: none;
}

.label {
  text-align: left;
}

.contact-submit {
  font-family: var(--primary-font);
    font-style: normal;
    font-weight: 800;
    font-size: var(--font-size-3);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #354e18;
    background-color: white;
    border-radius: 5px;
    padding: 8px 40px 8px 40px;
    border: 2px solid #354e18;
    cursor: pointer;
   
}
.contact-submit:hover{
  color: #f1f1f1;
  background: #F8830D;
  border: 2px solid #F8830D;
}

.contact-right-container {
  height: 60vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* background: rgb(173, 173, 173); */
}

.google-map{
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: 10px;
}

.contact-error {
  color: #e64848;
  font-size: 10px;
}


@media only screen and (max-device-width: 760px) {
  .contact-main-container {
    margin-bottom: 3vh;
  }

  .contact-title {
   align-items: center;
   justify-content: center;
  }

  .contact-section-1-container{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4vh;
  }


  .contact-form-left-container {
    height: 50%;
    width: 100%;
  }
  .contact-form-right-container {
    height: 50%;
    width: 100%;
  }
  .form-container {
    align-items: center;
  }
  .contact-form-container-warp {
    align-items: center;
    justify-content: center;
  }
  .contact-form-input-warp {
    align-items: center;
  }
  .label {
    align-self: start;
  }
  .form-input {
    width: 100%;
  }
  .form-text {
    width: 100%;
  }

  .contact-left-container {
    height: fit-content;
    width: 100%;
  }

  .contact-right-container {
    height: 50vh;
    width: 100%;
  }
}