.header-main-container {
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: rgb(255, 255, 255, 0.20); */
  background: white;
  position: fixed;
  top: 0;
  z-index: 99;
}

.header-logo-container {
  padding-left: 5%;
  display: flex;
  cursor: pointer;
}

.header-logo {
  width: 15vw;
}

.header-menu-container {
  display: flex;
  /* gap: 25px; */
  align-items: center;
  justify-content: center;
  padding-right: 5%;
}

.desktop-menu {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
  padding-right: 5%;
}


.header-menu-item {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  display: flex;
  align-items: flex-start;
  color: #2d4010;
}

/* .header-menu-item:hover {
    background: #E64848;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
  } */

.header-mobile-menu {
  display: none;
}

@media only screen and (max-device-width: 760px) {


  .header-mobile-menu {
    display: block;
    padding-right: 5%;
  }

  .desktop-menu {
    display: none;
  }

  .header-logo {
    width: 25vw;
  }

  .header-menu-item {
    display: none;
  }
}