.home-main-container {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 8vh;
    gap: 2vh;
    background-color: #ffffff;
    opacity: 1;
    /* background-image: radial-gradient(#50844d 0.4px, #ffffff 0.4px); */
    background-size: 8px 8px;
}