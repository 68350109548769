.category-main-container{
    height: fit-content;
  width: 100%;
  /* background: #000; */
  margin-top: 8vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  background-image: url("../../Assets/bg.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}

.category-title {
    height: 10vh;
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font-color-1);
  }
  
  .category-container{
    height: fit-content;
    width: 80%;
    margin-top: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 3vh;
  }