.project-box-container {
  height: auto;
  width: 20vw;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px;
  box-shadow: 20px 20px 40px #d9d9d9, -20px -20px 40px #ffffff;
  /* margin: 10px; */
}

.project-image {
/* width: 10vw; */
  /* height: 30vh; */
  object-fit: contain;
  height: 250px;
  width: 100%;
  border-radius: 10px;
  align-self: center;
}

.project-text-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
}

.project-title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  margin-bottom: -0.5vh;
  font-size: var(--font-size-2);
  color: var(--font-color-3);
}
.project-description {
  height: 20vh;
  font: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  display: flex;
  text-align: justify;
  word-break: break-word;
  font-size: var(--font-size-4);
  color: var(--font-color-3);
}

@media only screen and (max-device-width: 760px) {
  .project-box-container {
    width: 90%;
  }
  .project-description{
    height: auto;
  }
}
