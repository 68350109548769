.carousel-image-controller {
    height: 100vh;
    width: 100%;
    /* margin-top: 15vh; */
}

.carosel-image {
    height: 90%;
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-device-width: 760px) {
    .carousel-image-controller {
        height: 40%;
        width: 100%;
    }

    .carosel-image {
        height: 25vh;
        width: 100%;
        object-fit: cover;
    }
}