.about-main-container {
  height: fit-content;
  width: 100%;
  /* background: #000; */
  margin-top: 10vh;
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  opacity: 1;
  /* background-image: radial-gradient(#50844d 0.4px, #ffffff 0.4px); */
  background-size: 8px 8px;
}

.about-title {
  /* height: 10vh; */
  width: 80%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-1);
  display: flex;
  align-items: flex-start;
  color: var(--font-color-1);
}

.about-description {
  /* height: 10vh; */
  width: 80%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-2);
  display: flex;
  align-items: flex-start;
  color: var(--font-color-2);
}

.about-banner-container {
  height: 90vh;
  width: 100%;
}

.about-banner {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about-section-1-container {
  height: 90vh;
  width: 80%;
  background: rgb(202, 202, 202);
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-section-1-left {
  height: 100%;
  width: 50%;
  background: rgb(243, 242, 242);
}

.about-section-1-right {
  height: 100%;
  width: 50%;
  background: rgb(224, 223, 223);
}

.about-section-2-container {
  height: 90vh;
  width: 80%;
  background: rgb(202, 202, 202);
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-certificates-container {
  height: 50vh;
  width: 100%;
  /* background: #b8b8b8; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20%;
}

.about-certificates {
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 10%;
}

.about-certificate-title {
  width: 80%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color-1);
}

.image-warp {
  height: 25vh;
  width: 25vh;
  /* border-radius: 50%; */
}

.image-warp>img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-device-width: 760px) {


  .about-title {
    align-items: center;
    justify-content: center;
  }
  
  .about-certificates-container {
    height: fit-content;
    gap: 2vh;
  }

  .about-certificates {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5vh;
  }
}