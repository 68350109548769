.InfoGraphic-Main-container {
    /* height:100vh; */
    width: 100%;
    padding-top: 4vh;
    padding-bottom: 5vh;
    background-image: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(109,124,104,1) 0%, rgba(151,166,146,1) 100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2vh;
}

.InfoGraphic-Title {
    height: fit-content;
    width: 40%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

.InfoGraphic-Image_Container {
    height: 70%;
    width: 70%;
    /* background: rgb(198, 198, 198); */

}

.InfoGraphicImage {
    height: 100%;
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
}

@media only screen and (max-device-width: 760px) {
    .InfoGraphic-Title {
        width: 70%;
    }

    .InfoGraphic-Image_Container {
        height: fit-content;
        width: 90%;
        background: rgb(198, 198, 198);
        border-radius: 15px;
    }
}