.certification-main-container {
  height: fit-content;
  width: 80%;
  /* background: #000; */
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.certificate-title {
  height: 10vh;
  width: 100%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-1);
  display: flex;
  align-items: flex-start;
  color: var(--font-color-1);
}

.certificate-container {
  height: fit-content;
  width: 100%;
  /* background: rgb(228, 228, 228); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-direction: column; */
  flex-wrap: wrap;
  gap: 20px;
}


@media only screen and (max-device-width: 760px) {

  .certificate-title, .certificate-container{
    align-items: center;
    justify-content:center;
  }
}