.Admin-container{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Admin-Tooljet{
    height: 80%;
    width: 90%;
}

.Admin-auth-main-container{
    height: 100vh;
    width: 100%;
    /* background: #000; */
    color: aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Admin-auth-login-container{
    height: 60%;
    width: 45%;
    background: var(--box-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Auth_title{
    height: 20%;
    width: 100%;
    /* background: #000; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-1);
    color: #000;
}

.Auth_Form-container{
    height: 80%;
    width: 100%;
    /* background: #a72020; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-container-auth {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    /* padding: 15px; */
    /* background: rgb(190, 215, 45); */
  }

.contact-form-input-warp-auth{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: rgb(194, 14, 14); */
    gap: 5px;
}

.label-auth {
    width:80%;
    text-align: left;
  }

  input{
    padding-left: 10px;
} 

input::placeholder{
    padding-left: 10px;
}