.gallery-main-container {
  height: auto;
  width: 100%;
  /* background: rgb(161, 161, 161); */
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  opacity: 1;
  /* background-image: radial-gradient(#50844d 0.4px, #ffffff 0.4px); */
  background-size: 8px 8px;
}

.gallery-section-1-container {
  /* height: 60vh; */
  width: 80%;
  /* background: rgb(158, 19, 19); */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.gallery-section-1-left {
  /* height: 30vh; */
  width: 50%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-1);
  display: flex;
  align-items: flex-start;
  color: var(--font-color-1);
  /* background: #000; */
}

.gallery-section-1-right {
  /* height: 30vh; */
  width: 50%;
  /* background: rgb(85, 14, 14); */
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-2);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: justify;
  color: var(--font-color-1);
}

.gallery-section-2-container {
  height: fit-content;
  width: 80%;
  /* background: rgb(19, 98, 158); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-image {
  cursor: pointer;
  transition: transform .2s;
}

/* .gallery-image:hover {
    transform: scale(1.2);
  } */

@media only screen and (max-device-width: 760px) {
  .gallery-section-1-container {
    flex-direction: column;
    gap: 2vh;
  }

  .gallery-section-1-left,
  .gallery-section-1-right {
    margin: 0;
    height: auto;
    width: 90%;
    align-items: center;
    justify-content: center;
  }

}