.ProductCard-main-container {
    height: fit-content;
    width: 40%;
    /* background: #000; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 10vh;
    gap: 20px;
}

.productcard-image-container {
    height: fit-content;
    width: 50%;
}

.product-image {
    width: 100%;
    object-fit: cover;
}

.productcard-heading {
    /* height: 10vh; */
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font-color-1);
}

.productcard-subheading {
    /* height: 10vh; */
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-3);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--font-color-1);
}

.productcard-button {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-3);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    background: var(--button-color-1);
    padding: 12px 50px 12px 50px;
    cursor: pointer;
    text-decoration: none;
    max-width: 20%;
}


@media only screen and (max-device-width: 760px) {
    .ProductCard-main-container {
        width: 80%;
    }
}