.app-main-container{
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* opacity: 0.2; */

  /* background: #000; */
}

