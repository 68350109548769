.footer-main-container {
  height: fit-content;
  width: 100%;
  background: #E8EAE7 ;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 5vh;
  background-image: url("../../Assets/footerBg.svg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  /* box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff; */
}

.footer-container-1 {
  /* height: 15%; */
  width: 100%;
  background: #fc4349;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-container-1-social-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  /* margin-right: 20px; */
}

.footer-container-1-title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-2);
  color: white;
  margin-left: 30px;
}

.footer-container-2 {
  height: 75%;
  width: 100%;
  /* background: #E8EAE7 ; */
  
  /* background: #ffffff; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container-2-left {
  width: 40%;
  height: 100%;
  /* background: rgb(197, 196, 196); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container-2-left-warp {
  display: flex;
  flex-direction: column;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.footer-container-2-left-title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 900;
  font-size: var(--font-size-2);
  color: var(--font-color-3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container-2-left-content {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-3);
  color: var(--font-color-3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container-2-right {
  width: 50%;
  height: 100%;
  /* background: rgb(16, 151, 48); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container-2-right-warp {
  height: fit-content;
  width: 100%;
  /* background: rgb(207, 207, 207); */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 30px;
}

.footer-container-2-right-column {
  /* width: 50vw; */
  /* background: #000; */
  width: 70%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer-container-2-right-title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 900;
  font-size: var(--font-size-2);
  color: white;
}

.footer-container-2-right-content {
  /* width: 90%; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-3);
  display: flex;
  align-items: flex-start;
  color: white;
  gap: 10px;
}

.footer-container-2-right-content:hover {
  /* font-size: var(--font-size-2); */
  color: var(--primary-button-color);
  cursor: pointer;
}

.footer-container-3 {
  height: 10%;
  width: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container-4 {
  height: fit-content;
  width: 100%;
  background: #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* flex-direction: column; */
  gap: 5px;
}

.footer-container-3-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  display: flex;
  align-items: flex-start;
  color: #2d4010;
}

.footer-container-4-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  display: flex;
  align-items: flex-start;
  color: #2d4010;
  margin: 0;
}

.footer-image{
  width: 70%;
}

@media only screen and (max-device-width: 760px) {
  .footer-main-container {
    height: fit-content;
  }
  .footer-container-2 {
    /* width: 90%; */
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
  }
  .footer-container-1-social-container {
    margin-right: 0;
    padding: 2vh;
  }
  .footer-container-2-left {
    margin: 10px;
  }
  .footer-container-2-right {
    width: 100%;
    align-items: flex-start;
    margin: 10px;
  }
  .footer-container-2-right-warp {
    width: 100%;
    flex-direction: column;
  }
}

@media only screen and (max-device-width: 420px) {
  .footer-main-container {
    height: fit-content;
  }
  .footer-container-2-left-title {
    text-align: left;
  }
  .footer-container-2-left-content {
    text-align: left;
  }
  .footer-container-2-right-title {
    text-align: left;
  }
  .footer-container-2-right-content {
    text-align: left;
  }
  .footer-container-2 {
    width: 90%;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  .footer-container-2-left {
    margin: 10px;
  }
  .footer-container-2-right {
    width: 100%;
    align-items: flex-start;
    margin: 10px;
  }
  .footer-container-2-right-warp {
    width: 100%;
    flex-direction: column;
  }
  .footer-image{
    padding-left: 50px;
  }
}
