:root {
  --primary-background-color: #FFFFFF;
  --secondary-background-color: rgba(235, 242, 233, 0.4);
  --button-color-1: #85C12D;
  --box-color:#59CE8F;

  font-size: 1.1vw;
  --primary-font: 'poppins', sans-serif;
  --font-color-3: #85C12D; 
  --font-color-1: #354e18;
  --font-color-2: #354e18;
  --font-size-heading-1: 6rem;
  --font-size-heading-2: 3rem;
  --font-size-1: 2rem;
  --font-size-2: 1.5rem;
  --font-size-3: 1rem;
  --font-size-4: 0.8rem;
  --font-size-5: 0.5rem;


  /* --font-color-1: #FF8C00; */
}

.buttonStyle{
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-size-3);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #354e18;
  background-color: white;
  border-radius: 5px;
  padding: 8px 40px 8px 40px;
  border: 2px solid #354e18;
  cursor: pointer;
  text-decoration: none;

}
.buttonStyle:hover{
  color: #f1f1f1;
  background: #F8830D;
  border: 2px solid #F8830D;
}

@media only screen and (max-device-width: 760px) {
  :root {
    font-size: 2vw;
  }
}

@media only screen and (max-device-width: 420px) {
  :root {
    font-size: 3vw;
    text-align: center;
  }
}

body {
  background: #FFF;
  -ms-overflow-style: none;
  /* IE and Edge */
  overflow-x:hidden;
  scrollbar-width: none;

  
}

body::-webkit-scrollbar {
  display: none;
}