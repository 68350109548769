.services-main-container {
  height: 200vh;
  width: 100%;
  margin-top: 8vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.services-heading-section {
  height: 50%;
  /* width: 100%; */
  padding-left: 5vw;
  padding-right: 5vw;
  /* background: rgb(101, 34, 34); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.service-heading {
  height: 30%;
  width: 100%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.first-word {
  color: var(--font-color-2);
}

.service-content {
  height: 20%;
  font-size: var(--font-size-2);
  font-weight: 300;
  color: white;
  font-family: var(--primary-font);
  text-align: justify;
}

.services-container-section {
  height: 70vh;
  width: 50%;
}

/* scroll animation */

.scroll-main-container {
  height: 100vh;
  width: 100%;
  /* margin-top: 8vh; */
  /* color: #0f594d; */
  background-image: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(109,124,104,1) 0%, rgba(151,166,146,1) 100%);
  /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
}

.cd-header {
  position: relative;
  height: 100%;
  background: var(--primary-background-color);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.cd-header h1 {
  width: 90%;
  color: #f2e6cd;
  text-align: center;
  font-size: 2.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: -50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

@media only screen and (min-width: 768px) {
  .cd-header h1 {
    font-size: 3.6rem;
    font-weight: 300;
  }
}

.cd-fixed-background {
  position: relative;
  padding: 3em 5% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.cd-fixed-background h2,
.cd-fixed-background p {
  color: #f2e6cd;
}

.cd-fixed-background h2 {
  font-family: var(--primary-font);
  font-size: var(--font-size-1);
  font-weight: bold;
  color: var(--secondary-font-color);
  margin-bottom: 1em;
}

.cd-fixed-background ul>li {
  font-family: var(--primary-font);
  font-size: var(--font-size-2);
  font-weight: 400;
  color: var(--secondary-font-color);
  margin-bottom: 1em;
  text-align: justify;
}

.cd-fixed-background .cd-content::after {
  /* phone image on small devices */
  content: "";
  display: block;
  width: 100%;
  padding: 60% 0;
  margin: 2em auto 0;
}

.cd-fixed-background {
  background-color: white;
}



.cd-fixed-background.img-1 .cd-content::after {
  background: url("../../Assets/scroll_image/PLA-process1.png") no-repeat;
  background-size: 100% auto;
}

.cd-fixed-background.img-2 .cd-content::after {
  background: url("../../Assets/scroll_image/PLA-process2.png") no-repeat;
  background-size: 100% auto;
  /* background-color: var(--primary-background-color); */
}

.cd-fixed-background.img-3 .cd-content::after {
  background: url("../../Assets/scroll_image/PLA-process3.png") no-repeat;
  background-size: 100% auto;
}

.cd-fixed-background.img-4 .cd-content::after {
  background: url("../../Assets/scroll_image/PLA-process4.jpg") no-repeat;
  background-size: 100% auto;
}

.cd-fixed-background.img-5 .cd-content::after {
  background: url("../../Assets/scroll_image/PLA-process5.jpg") no-repeat;
  background-size: 100% auto;
}

@media only screen and (min-width: 768px) {
  .services-container-section {
    height: fit-content;
    width: 90%;
  }

  .cd-fixed-background {
    height: 100%;
    padding: 0;
    /* background-color: var(--secondary-background-color); */
  }

  .cd-fixed-background h2 {
    font-family: var(--primary-font);
    font-size: var(--font-size-1);
    font-weight: 900;
    color: var(--font-color-1);
    margin-bottom: 0.3em;
  }

  .cd-fixed-background p {
    font-family: var(--primary-font);
    font-size: var(--font-size-2);
    font-weight: 400;
    color: var(--font-color-1);
    margin-bottom: 1em;
    text-align: justify;
  }

  .cd-fixed-background .cd-content {
    width: 80%;
    position: absolute;
    left: 5%;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .cd-fixed-background .cd-content::after {
    display: none !important;
  }

  .cd-fixed-background.img-1 {
    background-image: url("../../Assets/scroll_image/step1-desktop-01.png");
  }

  .cd-fixed-background.img-2 {
    background-image: url("../../Assets/scroll_image/step2-desktop-01.png");
  }

  .cd-fixed-background.img-3 {
    background-image: url("../../Assets/scroll_image/step3-desktop-01.png");
  }

  .cd-fixed-background.img-4 {
    background-image: url("../../Assets/scroll_image/step4-desktop-01.png");
  }

  .cd-fixed-background.img-5 {
    background-image: url("../../Assets/scroll_image/step5-desktop-01.png");
  }


}

@media only screen and (min-width: 1048px) {
  .cd-fixed-background {
    background-attachment: fixed;
  }

  .cd-fixed-background .cd-content {
    width: 40%;
    right: 50%;
  }

  .cd-fixed-background h2 {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-1);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
    margin-bottom: 0.3em;
  }

  .cd-fixed-background p {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-2);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
    margin-bottom: 1em;
    text-align: justify;
  }
}

.home-section-warp {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home-section-6-container {
  height: 50vh;
  width: 100vw;
}

@media only screen and (max-device-width: 420px) {
  .home-section-6-container {
    height: 0;
  }

  .home-section-warp {
    height: fit-content;
  }

  .services-container-section {
    height: fit-content;
    width: 90%;
  }

  .scroll-main-container {
    height: fit-content;
    background: #ffffff;
    
    /* padding-bottom: 10vh; */
  }

  .service-heading, .service-content {
    color: var(--font-color-1);
  }

}