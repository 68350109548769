.OurProduct-main-container {
  /* height: 80vh; */
  width: 100%;
  /* background: rgb(231, 231, 231); */
  padding-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
}

.OurProduct-Title {
  /* height: 10vh; */
  width: 80%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-1);
  display: flex;
  align-items: flex-start;
  color: var(--font-color-1);
}

.OurProduct-Description {
  height: 10vh;
  width: 80%;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-2);
  display: flex;
  align-items: flex-start;
  color: var(--font-color-2);
}

.OurProduct-Image-Container {
  height: 60%;
  width: 90%;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2%;
}

.OurProduct-Image-Warpper {
  height: 100%;
  width: 22%;
  /* background: rgb(234, 29, 29); */
}

.OurProduct-Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-device-width: 760px) {
  .OurProduct-Title,
  .OurProduct-Description {
    justify-content: center;
    text-align: center;
  }

  .OurProduct-Description {
    height: fit-content;
  }

  .OurProduct-Image-Container {
    height: fit-content;
    margin-top: 15px;
  }

  .OurProduct-Image-Warpper {
    height: 100%;
    width: 65%;
    /* background: rgb(234, 29, 29); */
  }
}
