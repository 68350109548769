
   .testimonials-carousel-main-container{
    height: fit-content;
    width: 100%;
    /* background: rgb(255, 255, 255); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2vh;
}

.testimonials-carousel-title{
    height: 10vh;
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-1);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
}

.testimonials-description{
    height: fit-content;
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-2);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
    text-align: justify;
}

@media only screen and (max-device-width: 760px) {
  /* .testimonials-carousel-main-container{
      display: none;
  } */

  .testimonials-carousel-title{
    justify-content: center;
}
}