.ProductsDetails-Main-Container{
    height: fit-content;
    width: 98%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    
  /* Firefox */
}

.ProductDetails-Container{
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:2%;
    /* background: #000; */
}

.ProductsDetails-left-container{
    height: 80%;
    width: 40%;
    /* background: #000; */
    /* object-fit: cover; */
}

.ProductDetails-right-container{
    height: 100%;
    width: 49%;
    /* background: #000; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2%;
}

.ProductDetails-title{
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-1);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
}

.ProductDetails-title-2{
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 800;
    font-size: var(--font-size-2);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
}

.ProductDetails-description{
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-3);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
}

.ProductDetails-notes{
    margin-top: 10px;
    width: 80%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 800;
    font-size: var(--font-size-3);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
}

.ProductDetails-Button-Container{
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    gap: 2vh;
    margin-top: 20px;
}

.ProductDetails-Section-2{
    height: fit-content;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* background: #f10707; */
}

.ProductDetails-Section-2-left{
    height: fit-content;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 10vh;
    margin-top: 5vh;
    /* background: #000; */
}

.ProductDetails-Section-2-right{
    height: fit-content;
    width: 30%;
    /* background: #000; */
}


.Details-Container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1vh;
    /* background: rgba(123, 219, 29, 0.42); */
}

.Details-title{
    height: fit-content;
    width: fit-content;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-2);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
}

.Product-Details{
    height: 10vh;
    width: 100%;
    background-image: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(109,124,104,1) 0%, rgba(151,166,146,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Product-Details-Last{
    height: 10vh;
    width: 100%;
    background-image: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(109,124,104,1) 0%, rgba(151,166,146,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature-warp{
    width: 20%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Product-Details-feature{
    height: 10vh;
    width: 100%;
    /* background: var(--box-color); */
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Product-Details-category{
    height: fit-content;
    width: 80vw;
    /* background: var(--box-color); */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10%;
}

.Product-Details-category-2{
    height: fit-content;
    width: 100%;
    /* background: var(--box-color); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    /* flex-wrap: wrap; */
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Product-Details-category-2::-webkit-scrollbar {
    display: none;
  }
  
.Product-Details-category, .Product-Details-category-2 > div {
    text-align: center;
}

.detail-box-1{
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.detail-box-title{
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-2);
    display: flex;
    align-items: flex-start;
    color: white;
    text-transform: capitalize;
}

.detail-box-title-spl{
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-2);
    display: flex;
    align-items: flex-start;
    color: var(--font-color-1);
}

.detail-box-value{
    width: 50vw;
    /* background: #000; */
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.feature-warp, .category-warp{
    height: 100%;
    width: 25%;
    padding: 5px;
}


.feature-img{
    height: 35%;
    width: 90%;
}

.feature-warp > img{
    height: 90%;
    width: 90%;
    object-fit: contain;
    cursor: pointer;
}

.category-warp > img{
    height: 50%;
    width: 50%;
    object-fit: contain;
    cursor: pointer;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image{
    height: 70vh;
}


@media only screen and (max-device-width: 760px) {
    .ProductDetails-Container{
        height: fit-content;
        width: 100%;
        flex-direction: column;
        gap: 1vh;
    }
    .ProductsDetails-left-container{
        height: fit-content;
        width: fit-content;
    }
    .ProductDetails-right-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        width: 80%;
    }
    .ProductDetails-title-2, .ProductDetails-description{
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .ProductDetails-Button-Container {
        align-items: center;
        justify-content: center;
    }
    .Details-Container{
        width: 100%;
    }
    .ProductDetails-Section-2-right{
        width: 0;
    }
    .Product-Details-category-2{
        height: fit-content;
        width: 100%;
        /* background: var(--box-color); */
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20%;
        flex-wrap: wrap;
        /* overflow: scroll; */
    }

    .Product-Details{
        height: fit-content;
        flex-direction: column;
    }

    .detail-box-1{
        height: fit-content;
        width: fit-content;
        overflow-wrap: break-word;

    }

    .ProductDetails-Section-2-left{
        gap: 2vh;
    }
}