.slider-container {
  height: auto;
  width: 100%;
  background-color: white;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.slider-card {
  padding-top: 5vh;
  padding-bottom: 5vh;
  background-color: white;
}

/* .circleBase {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: rgba(128, 128, 128, 0.534);
  position: absolute;
  right: 2.5%;
  top: 2.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
} */

.fill {
  object-fit: cover;
}

.react-multiple-carousel__arrow, .react-multiple-carousel__arrow--left{
  display: none;
}