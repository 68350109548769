.DiscreteSlider-main-container{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3vh;
}

.DiscreteSlider-title{
    height:fit-content;
    width: 100%;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font-color-1);
    text-align: center;
}

.DiscreteSlider-image-container{
    height: 50%;
    width: 40%;
    background: var(--primary-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    /* object-fit:cover; */
    border-radius: 5%;
}

.DiscreteSlider-image {
    height: 100%;
    width: 100%;
    object-fit:contain;
    border-radius: 5%;
}

.DiscreteSlider-text{
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font-color-1);
    text-align: center;
}

@media only screen and (max-device-width: 760px) {
    .DiscreteSlider-main-container{
        height: 70vh;
    }

    .DiscreteSlider-title{
        width:80%;
    }
    .DiscreteSlider-image-container{
        width: 80%;
    }
}